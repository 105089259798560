import React, { useState } from 'react';
import './LiveBanner.css';

const LiveBanner = () => {
  const [showVideo, setShowVideo] = useState(false);

  const openVideo = () => {
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
  };

  return (
    <>
      <div className="live-banner">
        <div className="live-banner-content" onClick={openVideo}>
          <span>Click HERE to watch Diggnation Live @ SXSW!</span>
        </div>
      </div>

      {showVideo && (
        <div className="video-overlay">
          <div className="video-container">
            <button className="close-video" onClick={closeVideo}>×</button>
            <iframe 
              width="100%" 
              height="100%" 
              src="https://www.youtube.com/embed/EJ23yv8Bkcw?si=oiwCpSQ6TK94kcph&autoplay=1" 
              title="Diggnation Live" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default LiveBanner;