import React from 'react';

const Logo = () => {
  return (
    <svg className="logo" width="188" height="100" viewBox="0 0 188 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_38)">
        <path fillRule="evenodd" clipRule="evenodd" d="M28 0V24H0V76H48V0H28ZM28 60H20V40H28V60Z" fill="#FFFFFF"/>
        <path d="M76 24H56V76H76V24Z" fill="#FFFFFF"/>
        <path d="M56 0H76V16H56V0Z" fill="#FFFFFF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M140 24V76H168V84H140V100H188V24H140ZM160 60V40H168V60H160Z" fill="#FFFFFF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M84 76V24H132V100H84V84H112V76H84ZM104 40V60H112V40H104Z" fill="#FFFFFF"/>
      </g>
      <defs>
        <clipPath id="clip0_2_38">
          <rect width="188" height="100" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
